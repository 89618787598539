import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { Info } from '@maistodos/core-icons';
import {
  Box,
  Button,
  Loading,
  Toast,
  Typography,
  Flex,
} from '@maistodos/design-system-web';
import { ToastRef } from '@maistodos/design-system-web/types/components/Feedback/Toast/types';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { Alert } from 'components/Alert';

import { LinkStatus } from 'entities/LinkStatus';
import { PaymentOption } from 'entities/PaymentType';

import { useModal } from 'hooks/useModal';

import { useLink } from 'services/useLink';
import { usePayGenerate } from 'services/usePayGenerate';

import { copyToClipboard } from 'utils/copyToClipboard';
import { formatLocaleCurrency } from 'utils/formatLocale';

import {
  REFETCH_RUN_LIMIT,
  FIVE_SECONDS,
} from 'modules/checkout/components/Form/FormPix/constants';
import { qrCode } from 'modules/checkout/components/Form/FormPix/styles';
import { FormPixProps } from 'modules/checkout/components/Form/FormPix/types';
import { ModalError } from 'modules/checkout/components/ModalError';
import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';
import { SectionText } from 'modules/checkout/components/Section/SectionText';
import { SectionTitle } from 'modules/checkout/components/Section/SectionTitle';

export const FormPix = ({ id, amount, paymentType }: FormPixProps) => {
  const { t, i18n } = useTranslation();
  const toast = useRef<ToastRef>(null);

  const refetchTimes = useRef<number>(0);
  const [enabled, setEnabled] = useState<boolean>(false);

  const {
    isOpen: isOpenErrorModal,
    open: openErrorModal,
    close: closeErrorModal,
  } = useModal();

  const { data, isPending, error, isError } = usePayGenerate(
    PaymentOption.Pix,
    id,
    paymentType.id
  );
  const code = data?.pix?.qr_code || '';

  const formatted = useMemo<string>(
    () => formatLocaleCurrency(amount, i18n.language),
    [amount, i18n.language]
  );

  const errorTitle = useMemo(() => {
    if (error?.response?.data?.needs_to_pay_with_max_installments?.length) {
      return error?.response?.data.needs_to_pay_with_max_installments[0];
    }

    return t('Não foi possível processar seu pagamento.');
  }, [error?.response?.data.needs_to_pay_with_max_installments, t]);

  const onCopy = useCallback(() => {
    PaymentFlowAnalytics.onClickCopyCode();
    toast.current?.publish();
    copyToClipboard(code);
  }, [code]);

  useEffect(() => {
    if (code) {
      setEnabled(true);
    }
  }, [code]);

  useEffect(() => {
    if (isError) {
      openErrorModal();
    }
  }, [isError, openErrorModal]);

  // Long-polling to automatically redirect to the receipt screen if Pix got confirmed
  const { data: link } = useLink(id, {
    notifyOnChangeProps: [],
    enabled,
    refetchInterval: FIVE_SECONDS,
  });

  useEffect(() => {
    if (!link) {
      return;
    }

    if (link.status === LinkStatus.Paid) {
      PaymentFlowAnalytics.onApprovedPayment(PaymentOption.Pix);
    }

    if (refetchTimes.current === REFETCH_RUN_LIMIT) {
      setEnabled(false);
      return;
    }

    refetchTimes.current += 1;
  }, [link]);

  return (
    <div>
      {isPending && <Loading color="$brand500" fontSize="$h1" />}

      {isError && (
        <Alert variant="warning" title={errorTitle}>
          {t('Verifique as informações do seu pagamento e tente novamente.')}
        </Alert>
      )}

      {code ? (
        <Flex
          direction="column"
          css={{
            gridRowGap: '$spacing24',
            width: '100%',
            '@bp2': {
              width: '50%',
            },
          }}
        >
          <SectionGroup direction="column">
            <SectionTitle>{t('Valor do PIX')}</SectionTitle>
            <SectionText>{formatted}</SectionText>
          </SectionGroup>

          <SectionGroup direction="column">
            <SectionTitle>{t('Chave copia e cola:')}</SectionTitle>
            <SectionText css={{ wordBreak: 'break-word' }}>{code}</SectionText>
          </SectionGroup>

          <Button variant="primary" onClick={onCopy} testId="copy" fluid>
            {t('Copiar chave')}
          </Button>

          <Flex
            direction="column"
            css={{
              maxWidth: 180,
              width: '100%',
              marginLeft: '$auto',
              marginRight: '$auto',
            }}
          >
            <Box
              css={{
                height: 'auto',
                margin: '0 auto',
                maxWidth: 180,
                width: '100%',
              }}
            >
              {code && (
                <QRCode
                  size={256}
                  className={qrCode}
                  viewBox="0 0 256 256"
                  value={code}
                />
              )}
            </Box>

            <Typography variant="caption" align="center">
              {t('Mire a câmera do seu celular para o QRCODE')}
            </Typography>
          </Flex>
        </Flex>
      ) : null}

      <Toast
        ref={toast}
        icon={<Info color="currentColor" fontSize="1em" />}
        title={t('Código copiado com sucesso.')}
      />

      <ModalError
        isOpen={isOpenErrorModal}
        onClose={closeErrorModal}
        title={errorTitle}
        description={t(
          'Verifique as informações do seu pagamento e tente novamente.'
        )}
      />
    </div>
  );
};
